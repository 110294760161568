*{
    margin: 0;padding: 0;
    scroll-behavior: smooth;
}

.h-container{
    width: 100vw;
    height: 8vh;
    background-color: rgb(20, 39, 82);
    background-color: rgb(29, 29, 58);
    
    display: flex;
    justify-content: space-between;
    position: fixed;
    /* overflow: hidden; */
    /* position: fixed; */
}
.h-left p {
    
   
    font-size: 30px;
    font-weight: 700;
    margin: 5px 0px 10px 50px;
}
.h-right ul{
    display: flex;
    list-style: none;
    margin-top: 10px;

}
.h-right ul li a{
    color: white;
    font-size: 20px;
    font-weight: 500;
    list-style: none;
    padding: 20px 30px 0px 30px;
    margin-top: 10px;
    cursor: pointer;
    text-decoration: none;
    
    
    
}
.h-right ul li a:hover{
    color: aqua;
    text-decoration:underline;
    /* filter: brightness(200%); */
}
.h-right{
    margin-right: 30px;
}




/* ---------smaller-screen-------------- */

.menu-icon{
    display: none;
}
.close-icon{
    display: none;
}

@media screen and (max-width:500px){
    .h-left{
        margin-top: 10px;
    }
    .h-left p font{
        font-size: 30px;
        
    }
    .h-right ul {
        display: flex;
        flex-direction: column;
        background-color: aqua;
        
        position: fixed;
        top: 0;
        right: -150px;
        width: 150px;
        height: 100vh;
        /* padding-top: 50px; */
    }
    .h-right ul li a{
        color: black;
        /* margin: 25px 15px; */
    
    }
    .h-right ul li {
        margin-top: 20px;
        margin-left: 10px;
        
    }
    .menu-icon{
        display: block;
        color: white;
        font-size: 40px;
        margin-top: 15px;
    }
    .close-icon{
        
        display: block;
        color: black;
        font-size: 40px;
        margin-left: 30px;
        margin-top: 10px;

    }
    
}