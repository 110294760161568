.e-container{
    width: 100vw;
    height: 100vh;
    background-color: rgb(32, 32, 66);
    color: white;
    
}
.full-details{
    display: flex;
    justify-content: space-between;
}
.personal-details{
    margin-left: 30px;
    margin-top: 100px;
    
}
.personal-details h1{
    text-align: center;
    color: yellow;
    text-decoration: underline;
}
.name{
    margin-top: 30px;
    font-size: 20px;
    font-weight: 500;
}
.name span:nth-child(1){
    color: aqua;
    margin-right: 5px;

}
.educational{
    margin-top: 100px;
}
.educational h1{
    text-align: center;
    color: yellow;
    text-decoration: underline;
}
table{
    margin-right: 30px;
    margin-top: 30px;
    text-align: center;
    border: 2px solid black;
    border-radius: 5px;
    border: 3px solid rgb(27, 57, 252);
    box-shadow: 2px 2px 2px 2px rgb(27, 57, 252);
}
table thead th{
    background-color: rgb(132, 246, 246);
    padding: 5px 5px;
    font-weight: 500;
    color: black;
}
table tbody tr{
    background-color: rgb(252, 242, 229);
    color: black;
    font-weight: 500;
}
table tbody td{
    padding: 10px 5px;
}
.education-empty{
    width: 100vw;
    height: 50px;
    background-color: rgb(39, 91, 74);
   margin-top: 30vh;
}




/* -------smaller screen-------------- */

@media screen and (max-width:500px){
    .full-details{
        display: block;
       
    }
    .name{
        margin-top: 30px;
        font-size: 17px;
        font-weight: 500;
    }
    .name span:nth-child(1){
        color: aqua;
        margin-right: 5px;
    
    }
    .personal-details{
        margin-left: 20px;
        margin-top: 50px;
        
    }
    table{
        margin-right: 30px;
        margin-top: 30px;
        margin-left: 30px;
        
    }
    .e-container{
        width: 100vw;
        height: 100vh;
        background-color: rgb(32, 32, 66);
        color: white;
        
    }
    .educational{
       width: 360px;
      
    }
    .hide{
        display: none;
    }
}